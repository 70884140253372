.sidebar {
  width: 222px;
  position: fixed;
  left: 0;
  top: 65px;
  height: 100vh;
  border-right: 1px solid #D8E2E8;
  transition: all 0.2s;
  z-index:998;

  >ul{
    height:calc(100vh - 120px);
      overflow-y: auto;
  overflow-x: hidden;

  }
  ul {
    margin-top: 10px;
  }
  .nav-item {
    &.nav-item-border{
      border:0;
      position: relative;
      cursor: pointer;
      &::before{
        content:"";
        width:78%;
        height:1px;
        background-color:$grey-d8e;
        position:absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
      }
      .navbar-nav{
        margin-top:0;
      }
    }
    .nav-link {
      position: relative;
      color: #666;
      min-height: 40px;
      font-size:14px;
      svg{
        // height:22px;width:22px;
        margin-left:23px;}
      &:hover,&.active {
        color: #dba200;
        background-color: #fcf6e1;
      }
      &:hover::before,&.active::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        border-left: 3px solid #dba200;
      }
    }
  }
  @media(max-width:1199px){
    top:60px;height: calc(100vh - 60px);
      left:-250px;
  }
}