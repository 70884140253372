.nav-tabs{
    .nav-item{
        &:not(:last-child){margin-right:3px;}
        .nav-link{
            border:1px solid $grey-d8e;
            border-radius:2px;
            cursor: pointer;
            &.active{
                border-bottom-color:#fff;
            }
        }
    }
}
.line-tabs{
    border-bottom:1px solid rgba($grey-d8e, 0.5);
    .nav-item{
        .nav-link{
            border:0;
            margin-bottom:-1px;
            padding:0 0 10px;
            border-bottom:2px solid transparent;
            &.active{
                font-weight:500;
                border-color:$yellow-color;
            }
        }
        &:not(:last-child){
            margin-right:30px;
        }
    }
}