.filter{
    position:fixed;
    // background-color: #fff;
    width:310px;
    top:65px;
    left:223px;
    height:calc(100vh - 65px);
    overflow-y:auto;
    padding:0 10px 15px 10px;
    transition:all 0.2s;
    border-right:1px solid $grey-d8e;
    &.no-position{
        position: relative;
        top:inherit;
        height: inherit;
        width: 100%;
      border-right: 0;
        left: inherit;
        .filter-head {
            display:none !important;
        }

    }
    .result-card{
        border-color:$grey-d8e;
        position: relative;
        padding-bottom:24px;
        .show-all-result{
            position: absolute;
            width:100%;
            left:0;bottom:0;
            display:block;
            background-color:#F7F7F7;
            cursor: pointer;
        }
        .collapse{
            &:not(.show){
                
            }
           &.show{
            height:auto !important;
            overflow: visible;
           }
        }
       .result-collapse{
        display:block;
        height:80px !important;
        overflow: hidden;
        transition:all 0.3s;
        &.true{
            height:auto !important;
        }
       }
        ul{
            li{
                background-color:#FFFBEB;margin-top:8px;
                color:#000;padding:1px 13px 2px;border-radius:18px;
                span{font-size:12px;}
                svg{color:#DBA200;margin-left:5px;cursor: pointer;}
            }
        }
    }
    .filter-head{
        padding:15px 0 16px;
        position: relative;
        margin-bottom:5px;
        &:after{
            content:"";
            width:calc(100% + 20px);
            position: absolute;
            left: -11px;
            bottom: 5px;
            border-bottom:1px solid $grey-d8e;
        }
        .backarrow{
            transform:rotate(-90deg);
        }
    }
    .accordion-item{
        background-color:transparent;
        border:0;margin-top:15px;
        &:last-of-type{
            .accordion-button .collapsed{
                font-size: 16px;
            }
            .accordion-collapse{
                border-left:0;
            }
        }
        .accordion-button{
            background-color:$grey-f5f;
            font-size:15px;font-weight:600;
            &:not(.collapsed){
                &:after{
                    
                    background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                }
            }
            &:after{
                margin-right:0;
                margin-left:auto !important;
            }
        }
        .accordion-body{
            background-color:#fff;
            margin-top:6px;
            border:1px solid $grey-d8e;
            padding:15px;
            // .css-b62m3t-container{
            //     z-index:100;
            // }
            .input-icon{
                left: -10px;
                top: -8px;
                .form-control{height:35px;width: calc(100% + 20px);}
            }
            .checkbox-list{
                max-height: 210px;
                overflow-y: auto;
            }
            .css-1s2u09g-control, .css-1pahdxg-control{
                min-height:40px !important;
                border-radius: 0;
            }
        }
    }
    .filter-calender{
        .accordion-item{
            .accordion-body{
                background-color:transparent;
                border:0;
                padding: 0;;
            }
        }
        .react-datepicker{
            &__header{
                width:295px;
            }
            &__day,&__time-name,&__day-name{
                margin:0.278em;
            }
        }
    }
    @media(max-width:1599px){
        width:255px;
        .accordion-item{
            .accordion-body{
                padding:11px;
            }
        }
        .filter-calender{
            .react-datepicker{
                &__header{
                    width:222px;
                    padding:9px 0 8px;
                }
                &__current-month{
                    font-size:16px;
                }
                &__day-names{
                    margin-top: 6px;
                }
                &__day,&__time-name,&__day-name{
                    margin:0.122em;
                    font-size: 15px;
                    line-height: 25px;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    @media(max-width:1199px){
        left:-280px;top:0;
        z-index:1000;height:100vh;
        background-color:rgb(245, 248, 250);padding-left:15px;
        @include box-shadow(0,0,20px,rgba(#000, 0.1));
    }
}
.filter-btn{
    height:40px;
    width:40px;
}
.close-filter-btn{
    position: fixed;
    top: 0;
    left: -50px;
    transition:all 0.2s;
    .svg{height:25px;width:25px;}
}

.teams-page{
    table{
        thead{
            tr{
                th{white-space:normal;}
            }
        }
    }
    .common-loader{
        height:calc(100vh - 157px);
    }  
}

.filter-desktop-btn{
    background-color: #e6eef4;
    padding: 3px 12px;
    border-radius: 4px;
    width: 85px;
    @media(min-width:1200px){
        display:none;
    }
    @media(max-width:1199px){
        display:none;
    }
}

.filter-page{
    padding-left:315px;
    position: relative;
    transition:all 0.2s;
    .common-loader{
        height:calc(100vh - 157px);
    }
    @media(max-width:1599px){
        padding-left:255px;
    }
    @media(min-width:1200px){
        &.open-filter{
            padding-left:0 !important;
            .top-head{
                padding-left:100px;
                position: relative;
            }
            .filter-desktop-btn{
                display:block;
                position: absolute;
                left:0;top:22px;
            }
           .filter{
           left:-600px;
           }
        }
           &.close-filter{
            .filter-desktop-btn{
                display:none;
            }
            .filter{
             left:223px;
            }
         }
    }
    @media(max-width:1199px){
        padding-left:0;
        &.open-filter{
            .close-filter-btn{left:256px;}
            .filter{left:0;}
        }
    }
}