.pagination-row{
    .option-select{
        .form-select{
            color:$blue-color;
        }
    }
        .page-item{
            &:first-child,&:last-child{
                .page-link{
                    border-radius:3px !important;
                }
            }
            &:first-child{
                margin-right:8px;
            }
            &:last-child{
                margin-left:8px;
            }
        .page-link{
            color:#006BA7;
            border-color:$grey-e0e;
            box-shadow: none;
            font-size:17px;
            line-height:22px;
            padding:6px 10px;
            &:hover,&:focus,&.active{
               background-color:$dark-blue;
               color:#fff;
            } 
        }
        &.active{
           .page-link{
            background-color:$dark-blue;
               color:#fff;
           }
        }
        &:nth-child(2){
           .page-link{
            border-radius:3px 0 0 3px;
            padding:6px 7px;
            svg{margin-left:-2px;}
           }
        }
        &:nth-last-child(2){
            .page-link{
                border-radius:0 3px 3px 0;
                padding:6px 7px;
                svg{margin-right:-3px;}
            }
        }
        }
    @media(max-width:575px){
        flex-wrap:wrap;justify-content:center;
        .pagination{
            flex-wrap:wrap;
            justify-content:center;
            .page-item{
                margin-bottom:10px;
            }
        }
    }
}