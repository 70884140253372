.form-label{
    color:$grey-6a6;
}
.form-control{
 height:50px;
 border-color:$grey-d8e;
 border-radius: 4px;
 padding: 5px 15px;
 font-size: 14px;
 &-sm{
    height:40px;
    border-radius:2px;
 }
 &-md{
    height:45px;
 }
 @include placeholder(rgba($grey-6a6, 0.5));
 &:focus{
    border-color:$yellow-color;
 }
}
.reason-text{
    .form-control{
        min-height:120px;
    }
}
.form-select{
    box-shadow: none !important;
    font-size: 14px;
    cursor: pointer;
    border-color:$grey-d8e;
    border-radius: 4px;
    &:focus{
        border-color:$yellow-color;
     }
}
.rc-time-picker{
    display: block !important;

    .rc-time-picker-input{
        height: 45px;
        padding:4px 15px;
        font-size:14px
    }
    .rc-time-picker-clear{
        top:11px;right:9px;
        i{
            &:after{
                font-size:20px;
            }
        }
    }
}
.desc-input{
    height:70px;
    resize:none;
}
.react-select{
   
    &__menu-list{
      max-height:200px !important;
      
    }
    &__control{
        min-height:45px !important;
        border-color:$grey-d8e !important;
        font-size:14px;
        box-shadow:none !important;
        padding-left:6px !important;;
    }
    &__menu{
        z-index:999 !important;
    }
    &__indicator-separator{
        display:none !important;
    }
    &__dropdown-indicator{
        &:after{
            content:"";
            width: 0; 
            height: 0; 
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #9C9D9F;
            border-radius: 3px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
        }
        svg{
            display: none;
        }
    }
    &__option{
        font-size: 14px !important;
        padding:6px 15px !important;
        &--is-focused{
            color:$black-3c3 !important;
            background-color:#fcf6e1 !important;
            cursor: pointer !important;
        }
        &--is-selected{
            color:$black-3c3 !important;
            background-color:rgba($yellow-color, 0.7) !important;
            cursor: pointer !important;
        }
    }
}

.css-1insrsq-control{
    min-height:45px !important;
}
.css-1s2u09g-control,.css-1pahdxg-control,.css-cj7im2-control,.css-1i1mi4d-control{
    .css-g1d714-ValueContainer{
        padding-left:0;
        .css-1rhbuit-multiValue{
            border: 1px solid #F5F8FA;
            background-color: #F5F8FA;
            border-radius: 50px;
            .css-xb97g8:hover{
                background-color:transparent;
                 color:#666;
            }
        }
    }
    .css-1okebmr-indicatorSeparator,
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer{
        display: none !important;
    }
    .css-1okebmr-indicatorSeparator{
       ~ .css-tlfecz-indicatorContainer,
       ~ .css-1gtu0rj-indicatorContainer{
            display: block !important;
        }  
    }
}
@media(max-width:991px){
    .css-1insrsq-control{
        min-height:40px !important;
    }
    .rc-time-picker{
        .rc-time-picker-input{
            height:40px;
        }
    }
    .react-select{
        &__control{
            min-height: 40px !important;
        }
    }
}
.css-1pahdxg-control{
    border-color:$yellow-color !important;
}
.input-icon{
    position: relative;
    svg{
        position:absolute;
        top:50%;transform:translateY(-50%);
        left:15px;color:$grey-6a6;
    }
    .form-control{
        padding-left:38px;
    }
}
@media(max-width:991px){
    .form-control{
        &-md{
            height:40px;
        }   
    }
}
@media(max-width:767px){
    .form-control{
        height:44px;
        &-md,&-sm{
            height:40px;
        } 
    }
}

.form-check{
    margin-bottom:10px;
   &:not(:last-child){
    margin-bottom:14px;
   }
   label{color:$grey-6a6;font-size: 14px;}
   .form-check-input{
    height:16px;
    min-width:16px;
    max-width:16px;
    margin-right:10px;
    cursor: pointer;
    &:checked{
        background-color:$dark-yellow;
        border-color:$dark-yellow;
    }
    &:focus{box-shadow: none;border-color:$dark-yellow}
   }
    input[type="checkbox"]{
        border-radius:0;
        margin-top:2px;
    }
    input[type="radio"]{
        margin-top:1px;
    }
    @media(max-width:1599px){
        .form-check-input{
            height:16px;
            min-width:16px;
            max-width:16px;
            margin-right:6px;
        }
    }
}
.calendarIcon{
    position: relative;
    svg{
        position: absolute;
        top: 35px;
        right: 12px;
        height: 28px;
        width: 28px;
        fill: #d2d4d7;
    }
}
.dollarIcon{
    position: relative;
    svg{
        position: absolute;
        top: 42px;
        left: 3px;
        height: 15px;
        width: 16px;
        fill: #545455;
        padding-right: 2px;
    }
}
.toggle-btn{
    position: absolute;
    top: -22px;
    right: 0;
    z-index: 10;
    label{font-size:10px !important;}
    // .FormikToggleSwitch_inner__X\+GBC{
    //     &:after,&:before{
    //         height:20px;line-height:25px;
    //         font-size: 10px !important;padding:0;
    //         text-align:center;
    //     }
    // }
}