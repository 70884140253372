.report-page{
    .accordion-card{
        border-color:$grey-d8e;
        min-height:500px;
        .card-header{
            // padding:9px 56px;
            border-color:$grey-d8e;
        }
      .card-title{
        font-size: 12px;
        font-weight: 700;
        color:$grey-6a6;
      }
      .accordion-button{
        display: block;
        .dtl-head{
            border:0 !important;
        }
        &:after{
            display: none;
        }
        span{font-size: 14px;font-weight:500;}
      }
      .parent-header{
        padding:14px 22px;
      }
      .accordion-body{
        padding:0;
    }
      .parent-body{
        >.accordion-body{
            padding:0;
        }
        .dtl-head{
            padding:6px 56px;
            background-color:$light-blue;
            border-top:1px solid $grey-d8e;
           [class^="col-"]{
            > span{font-size:11px;font-weight:500;}
           }
        }
        .pdtl-head{
            padding:9px 78px;
            background-color:$light-grey;
        }
        .accordion-item{
            border-left:0;
            border-right:0;
            &:first-of-type{
                border-radius: 0;
            }
            &:last-of-type{
                border-radius: 0;
                border-bottom:0;
            }
        }
      }
     table>tbody>tr>td:nth-child(1),
     table>thead>tr th:nth-child(1) {
        position: sticky;
        position: -webkit-sticky;
        left: -1px;
        z-index: 2;
        min-width:170px;
        width:170px;
        padding-left:53px;
        text-align:left;
    }
    .team-slot-table{
        table>tbody>tr>td:nth-child(1),
        table>thead>tr th:nth-child(1){
            padding-left:106px;
        }
    }
      table{
        th{
            font-weight:600;
            font-size: 14px;
            white-space:nowrap;
            padding:8px 18px;
            &:not(:last-child){
                border-right:1px solid $grey-d8e;
            }
        }
        .top-heading{
            th{
                background-color:rgba($light-blue, 0.5);
                border-top:1px solid $grey-d8e;
                &:first-child{
                    background-color:rgba($light-blue, 0.5);
                }
            }
        }
        .bottom-heading{
            background-color:#fff9ea;
            th{
                width:88px;
                min-width:88px;
                text-align:right;
                &:first-child{
                    background-color:#fff9ea;
                }
            }
        }
        td{
            font-size: 14px;
            white-space:nowrap;
            border-color:$grey-d8e;
            padding:8px 18px;
            text-align:right;
            &:first-child{
                background-color:$white;
            }
            &:not(:last-child){
                border-right:1px solid $grey-d8e;
            }
        }
      }
    }
}

.reports-accordion-page{
    .reports-card{
        min-height:500px;
        .card-header{
            padding:8px 20px 8px 55px;
            span{font-size: 11px;}
        }
        .text-ellipses{
            white-space:nowrap;
            display: block;
            text-overflow:ellipsis;
            overflow:hidden;
        }
        .accordion-header{
            .accordion-button{
                display:block;
                padding:12px 20px 11px 55px !important;
                &:after{
                    display:block;
                    position: absolute;
                    left:25px;
                    top:13px;
                }
            }
        }
        .accordion-body{
            padding: 0;
            .data-list{
                max-height:250px;
            }
            .team-list{
                background-color:$grey-f0f;
                padding:7px 20px 7px 58px;
                border-bottom:1px solid $grey-d8e;
            }
        }
    }
    .no-accrodion-arrow .accordion-header{
         .accordion-button {
            padding-left: 15px !important;
          }
    }
    @media(max-width:575px){
        .scroll-card{
            width:575px;
        }
        .reports-card{
            overflow-x:auto;
        }
    }
}


.reports-accordion-page .reports-card .no-accordion-arrow .accordion-header .accordion-button {
    padding-left: 20px !important;
}
.reports-accordion-page .reports-card .card-header.no-accordion-arrow {
    padding-left: 20px !important;
}