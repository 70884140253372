@mixin box-shadow($top, $left, $blur, $color) {
    -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
  }

  @mixin  placeholder($color) {
    &::-webkit-input-placeholder { /* Edge */
      color:$color;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:$color;
    }
    &::placeholder {
      color:$color;
    }
  }