.utlization-page {
    .legendsDetail{
        .legends-item{
            padding-left:25px;
            svg{
                position:absolute;
                left:0;top:2px;
            }
        }
    }
    .report-header {
        padding: 10px;
    }
    .total-show-data{
        border-radius: 3px;
        padding:0 18px 6px;
        span{
            display:inline-block;
            vertical-align:middle;
            line-height: 18px;
            margin-top:6px;
        }
        li{
            &:not(:last-child){
                margin-right:16px;
            }
        }
    }
// bootstrap bg color
    .bg-info{
         background-color:#3390BE !important;
    }
    .bg-warning{
        background-color:$dark-yellow !important;
    }
    .bg-success{
        background-color:#0F8012 !important;
    }
    .bg-danger{
        background-color:#A30C5D !important;
    }
    .bg-secondary{
        background-color:#4079A3 !important;
    }
    .bg-dark{
        background-color:#169F9F !important;
    }
// font size
    .utilization-accordion{
        border:1px solid $grey-d8e;
        background-color:$white;
        min-height:500px;
        .accordion-item{
            border-radius: 0 !important;
            border:0 !important;
            border-top:1px solid $grey-d8e;
            &:first-child{
                border-top:0;
            }
        }
        .accordion-header{
            background-color:$grey-bg;
            h5{font-size: 14px;}
            padding: 12px 22px;
            border-bottom:1px solid $grey-d8e;
            .accordion-button{
                background-color: transparent;
               width:100%;
            }
        }
        .accordion-body{
            padding: 0;
            .data-list{
                max-height:300px;
                overflow-y:auto;
                overflow-x:hidden;
            }
            .total-show-data{
                border:0;
                margin:4px;
                padding-left:53px;
            }
            .data-head{
                background-color:$light-blue;
                padding:10px 22px 10px 57px;
                font-size: 13px;
                font-weight:600;
                [class^="col-"]{
                    &:not(:first-child){
                        margin-left:-2px;
                    }
                }
            }
            .accordion{
                border:0 !important;
                .accordion-item{
                    border:0 !important;
                }
                .accordion-header{
                    background-color: $white;
                    padding:0;
                    .employee-name{
                        width: calc(100% - 20px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .accordion-button{
                        display:block;
                        padding:12px 22px 12px 58px !important;
                        &:after{
                            display: block;
                            position: absolute;
                            left:58px;
                            bottom: 15px !important;
                        }
                    }
                }
                .accordion-body{
                   .project-list{
                       background-color:$grey-f0f;
                       padding:9px 22px 9px 58px;
                       border-bottom:1px solid $grey-d8e;
                   }
                }
            }
        }
    }
}