.blank-slot{
width: 300px;
}

.screenshots-page{
    .capture-dropdown{
        min-width:230px;
        border-radius: 2px;
        border-color:$grey-d8e;
        padding:10px 15px;
        z-index:10;
        &:before{
            content:"";
                position:absolute;
                top:-8px;
                left:10px;
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $grey-d8e;
                border-radius:10px
        }
    }
    .screenshot-row{
        img{
            @include box-shadow(3px,1px,15px,rgba($black-3c3, 0.1));
        }
       .time-box{
        background-color:rgba($white, 0.9);
        position: sticky;
        top:0;left:0;
        z-index:1;
        p{
            font-size:16px;
            font-weight:600;
            position: absolute;
            min-width: 63px;
            height: 32px;
            background-color:rgba($white, 0.9);
            top: 0;
            left: 0;
            padding: 4px 10px;
            @include box-shadow(0,0,20px,rgba($black-3c3, 0.5));
        }
       }
       .cross-icon{
        background-color:$white;
        // color:$white;
        top:7px;right:4px;
        border-radius:50%;
        height:25px;
        width:25px;
        @include box-shadow(0,0,20px,rgba($black, 0.2));
        cursor: pointer;
        svg{
            margin:-1px -1px 0 0;
        }
       }
       &:first-child{
        margin-top:10px;
       }
       &:not(:last-child){
        margin-bottom:30px;
       }
    }
}
