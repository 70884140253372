.modal{
    .modal-header{
        .btn-close{
            box-shadow: none;
        }
    }
    @media(max-width:767px){
        .modal-header{
            .modal-title{
                font-size: 18px;
            }
        }
    }
}

.alertmodal{
    // .modal-header{
    //     display:none;
    // }
    .modal-body{
       padding:25px 20px;
    }
}

.calenderModal {
    max-width:100%;
    .modal-content{
        display:inline-flex;
        width: auto;
        margin: 0 auto;
    }
}
.termsandconditionsmodal {
    display: block; /* Make sure the modal is displayed */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(55, 52, 52, 0.85); /* Semi-transparent overlay */
    z-index: 9999; /* Ensure the modal is on top of other content */
  }
  
  .termsandconditionsmodal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 40%;
  }
  .openWalkthroughConditionsmodal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 25%;
  }
  
  .termsandconditionsmodal-header {
    padding-bottom: 4px;
  }
  
  .termsandconditionsmodal-footer {
    padding-top: 10px;
    border-top: 1px solid #ccc;
  }
  
  .termsandconditionsmodal-body {
    padding: 10px 0;
    font-size: 12px;
  }
  