.table-list{
    border:1px solid $grey-d8e;
    min-height:500px;
    overflow:hidden;
    background-color:$grey-fcf;
    position: relative;
    >.table-responsive{
        min-height:500px;
    }
    table{
       thead tr th.table-action,
       tbody tr td.table-action{
        position: sticky;
        position: -webkit-sticky;
        right: 0;
        z-index: 2;
        min-width:75px;
        width:75px;
        text-align: center;
       }
       thead tr th.table-action{
        background-color:$grey-bg;
       }
       tbody tr td.table-action{
        background-color:#f8f8f8;
       }
        thead{
            th{
                font-weight:500;
                border-bottom:1px solid $grey-d8e;
                padding:10px 15px;
                font-size: 12px;
                font-weight:700;
                color:$grey-6a6;
                vertical-align:middle;
                background-color:$grey-bg;
                text-transform: uppercase;
                white-space:nowrap;
            }
        }
        tbody{
            tr{
                position: relative;
                // &.expand-row{
                //     height:300px;
                //     td{
                //         vertical-align:initial;
                //     }
                //     ~.child-table{
                //         &.project-child-table{
                //             margin-top:-250px;
                //             z-index:3;
                //         }
                //     }
                // }
                &:not(:last-child){
                    border-bottom:1px solid $grey-d8e;
                }
            }
            td{
               padding:12px 15px;
               font-size:14px;
               vertical-align:middle;
               white-space:nowrap;
               max-height:50px;
               &.table-action{
                text-align: center;
                svg{
                    color:rgba($grey-6a6, 0.6);
                    cursor: pointer;
                    &:hover{
                        color:$yellow-color;
                    }
                    &:not(:last-child){
                        margin-right:6px;
                    }
                }
            }
            }
        }
       
    }
    .child-table{
        background-color:#f8f8f8;
        position: relative;

        .head-row{
            padding:10px 15px;
            .btn{
                padding:6px 10px;
            }
        }
        .table-responsive{
            max-height:230px;
        }
        &.project-child-table{
            border-top:1px solid $grey-d8e;
            // position: absolute;
            // right:0;left:0;
            position: sticky;
            left:0;
            // height:250px;
            // width: 100%;
            thead tr th.table-action,
            tbody tr td.table-action{
                z-index:7;
                right:1px;
            }
            .table-responsive{
                // height:188px;
                max-height: initial;
            }
            thead tr th.table-action{
                background-color:$white;
                top:0;
                z-index: 10;
             }
             tbody tr td.table-action{
                background-color:$white;
             }
        }
        .common-loader{
            height:150px;
        }
        
        thead{
            th{
                background-color:$light-blue;
                position:sticky;
                top:0;
                z-index:6;
            }
        }
    }
}
.checkbox-table{
    .ReactVirtualized{
        &__Table{
            &__headerColumn{
                &:first-child{
                    max-width:35px;
                    min-width:35px;
                }
            }
            &__rowColumn{
                &:first-child{
                    max-width:35px;
                    min-width:35px;
                }
            }
        }
    }  
}
.ReactVirtualized{
    &__Table{
        &__headerRow{
            display: flex;
            // white-space:nowrap;
            overflow:visible !important;
            background-color:$grey-bg;
            align-items:center;
            justify-content:space-between;
            // height:inherit !important;
        }
        &__headerTruncatedText{
            &[title="ACTION"]{
                display: block;
                text-align: center;
                width:100%;
            }
        }
        &__headerColumn{
            // flex:inherit !important;
            margin:0 !important;
            padding:0 15px;
            font-size: 12px;
            font-weight:700;
            color:$grey-6a6;
            // max-width:100px;
            // min-width:100px;
            align-items: center;
            display: flex;
            height:100%;
        }
        &__row{
            display:flex;
            white-space:nowrap;
            align-items:center;
            border-top:1px solid $grey-d8e;
            width:100% !important; 
            justify-content:space-between;
        }
        &__rowColumn{
            // flex:inherit !important;
            margin: 0 !important;
            padding: 15px;
            font-size:14px;
            // max-width:100px;
            // min-width:100px;
            .btn{ font-size:14px;text-decoration: none;}
        }
        .table-action{
            text-align: center;
            svg{
                color:rgba($grey-6a6, 0.6);
                cursor: pointer;
                &:hover{
                    color:$yellow-color;
                }
                &:not(:last-child){
                    margin-right:6px;
                }
            }
        }
    }
    &__Grid{
        //   overflow:hidden !important;
        &__innerScrollContainer{
          overflow:auto !important;
          pointer-events: inherit !important;
          .expand-row{
            // overflow:auto !important;
            // overflow-x:hidden !important;
            .expand-row{
                overflow:hidden !important;
            }
          }
        }
    }
    
}

.child-table{
    .ReactVirtualized{
        &__Grid{
            &__innerScrollContainer{
              overflow:auto !important;
              >.ReactVirtualized__Table__row{
                overflow:hidden !important;
              }
            }
        }
        &__Table{
            &__headerRow{
                // height:48px !important;
                margin-top:-200px;
                background-color:$light-blue;
            }
        }
    }
}

.expandable-table{
    .table-list{
        >.table-responsive{
           >.table{
            display:table;
            table-layout:fixed;
            .table-expand-btn{
                position: relative;
                white-space:normal;
                padding-left:22px;
                button{
                   position: absolute;
                   left:0;top:-2px;
                   height:13px; 
                }
            }
            .alert-danger{
                margin-bottom:50px !important; 
            }
            .child-table{
                .head-row{
                    padding-left:38px;
                }
                thead{
                    th{
                        width:auto !important;
                        &:first-child{
                            padding-left:38px;
                        }
                    }
                }
                tbody{
                    td{
                        &:first-child{
                            padding-left:38px;
                        }
                    }
                }
            }
           }
        }
    }
    &.timesheet-summary-table{
        .table-list{
            >.table-responsive{
               >.table{
                display:table;
                table-layout:fixed;
                tbody{
                    td{
                        &:nth-child(2){
                           white-space:normal;
                          } 
                    }
                }
                thead{
                    th{
                       &:not(.table-action){
                        width:100px;
                       }
                      &:first-child{
                        width:185px;
                      } 
                      &:nth-child(2){
                        width:265px;
                      }
                      &:nth-child(3){
                        width:225px;
                      }
                      &:nth-child(4){
                        width:200px;
                      }
                      &:nth-child(5){
                        width:125px;
                      }
                      &:nth-child(6){
                        width:150px;
                      }
                      &:last-child{
                        width:180px;
                      } 
                    }
                }
               }
            }
        }
    }
    &.timesheet-summary-table{
        .table-lists{
            >.table-responsive{
               >.table{
                display:table;
                table-layout:fixed;
                tbody{
                    td{
                        &:nth-child(2){
                           white-space:normal;
                          } 
                    }
                }
                thead{
                    th{
                       &:not(.table-action){
                        width:100px !important;
                       }
                      &:first-child{
                        width:185px;
                      } 
                      &:nth-child(2){
                        width:265px !important;
                      }
                      &:nth-child(3){
                        width:225px;
                      }
                      &:nth-child(5){
                        width:125px;
                      }
                      &:nth-child(6){
                        width:150px;
                      }
                      &:last-child{
                        width:180px;
                      } 
                    }
                }
               }
            }
        }
    }
    &.timesheet-summary-table-role{
        .table-list{
            >.table-responsive{
               >.table{
                display:table;
                table-layout:fixed;
                tbody{
                    tr{
                        td{
                            &:nth-child(4){
                                display: none;
                               }
                               &:nth-child(5){
                                  display: none;
                               }
                               &:nth-child(6){
                                 display: none;
                               }
                        }
                    }
                }
                thead{
                    th{
                       &:not(.table-action){
                        width:100px;
                       }
                      &:first-child{
                        width:185px;
                      } 
                      &:nth-child(2){
                        width:265px;
                      }
                      &:nth-child(3){
                        width:225px;
                      }
                      &:nth-child(4){
                       display: none;
                      }
                      &:nth-child(5){
                         display: none;
                      }
                      &:nth-child(6){
                        display: none;
                      }
                      &:last-child{
                        width:180px;
                      } 
                    }
                }
               }
            }
        }
    }
    &.employees{
        .table-list{
            >.table-responsive{
               >.table{
                display:table;
                table-layout:fixed;
                tbody{
                    td{
                        &:nth-child(5){
                            white-space: normal;
                          }  
                    }
                }
                thead{
                    th{
                       &:not(.table-action){
                        width:100px;
                       }
                      &:first-child{
                        width:185px;
                      } 
                      &:nth-child(4){
                        width:220px;
                      }
                      &:nth-child(5){
                        width:150px;
                      }
                      &:nth-child(6){
                        width:150px;
                      }
                      &:nth-child(7){
                        width:150px;
                      }
                      &:nth-child(8){
                        width:150px;
                      }
                    }
                }
               }
            }
        }
    }
    &.project{
        .table-list{
            >.table-responsive{
               >.table{
                display:table;
                table-layout:fixed;
                tbody{
                    td{
                        &:first-child{
                            white-space:normal;
                        }
                    }
                }
                thead{
                    th{
                       &:not(.table-action){
                        width:150px;
                       }
                      &:first-child{
                        width:220px;
                      } 
                      &:nth-child(7){
                        width:150px;
                      }
                      &:nth-child(8){
                        width:150px;
                      }
                    }
                }
               }
            }
        }
    }
}
.manual-timesheet-table {
    .table {
        thead {
            th {
                &:nth-child(5) {
                    width: 200px;
                    min-width: 200px;
                }

                &:nth-child(6),
                &:nth-child(11) {
                    width: 200px;
                    min-width: 200px;
                }
            }
        }

        tbody {
            td {

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(11) {
                    white-space: normal;
                    word-break: break-all;
                }
            }
        }
    }
}

.timesheet-table {
    .table {
        thead {
            th {
                &:nth-child(3) {
                    width: 250px;
                    min-width: 250px;
                }

                &:nth-child(4) {
                    width: 325px;
                    min-width: 325px;
                }
            }
        }

        tbody {
            td {

                &:nth-child(3),
                &:nth-child(4) {
                    white-space: normal;
                    word-break: break-all;
                }
            }
        }
    }
}

.roles-table{
    border:1px solid rgba(#D0D6DB, 0.8);
    th,td{
      &:first-child{
        border-right:1px solid rgba(#D0D6DB, 1);
        width:280px;
      }
    }
    th{
      font-size: 15px;
      padding: 8px 15px 7px;
    }
    tr{
      &:last-child{
        td{
          border-bottom:0;
        }
      }
      td{
        background-color:#F8F8F8;
        padding:7px 15px;
        font-size: 15px;
        border-bottom-color:#D0D6DB;
        .form-check-input{
          &[type=checkbox]{
            width:20px;
            height:20px;
            border-radius:2px;
            border:2px solid #D1DDE4;
            box-shadow: none !important;
            margin-top: 2px;
          }
        }
      }
      &.first-row-active{
        td{
          background-color:#D5ECFD;
        }
      }
      &.third-row-active{
        td{
          background-color:rgba(#EEEEEE, 0.9);
        }
      }
      &.fourth-row-active{
        td{
        //   background-color:darken(#EEEEEE, 2);
        background-color: "#f6f6f6";
        }
      }
    }
  }

@media(min-width:1600px){
    .child-table{
        &.project-child-table{
            width:calc(100vw - 589px); 
        }
        .common-loader{
            width:calc(100vw - 589px); 
        }
    }
    .open-filter{
        .child-table{
            &.project-child-table{
                width:calc(100vw - 273px);
            }
            .common-loader{
                width:calc(100vw - 273px);
            }
        } 
    }
    .close-sidebar{
      .open-filter{
        .child-table{
            &.project-child-table{
                width:calc(100vw - 126px);
            }
            .common-loader{
                width:calc(100vw - 126px);
            }
        } 
      }
    }
    .close-sidebar{
        .close-filter{
          .child-table{
              &.project-child-table{
                  width:calc(100vw - 381px);
              }
              .common-loader{
                  width:calc(100vw - 381px);
              }
          } 
        }
      }
}

@media(min-width:1200px) and (max-width:1599px){
    .child-table{
        &.project-child-table{
            width:calc(100vw - 528px);
        }
        .common-loader{
            width:calc(100vw - 528px);
        }
    }
    .open-filter{
        .child-table{
            &.project-child-table{
                width:calc(100vw - 273px);
            }
            .common-loader{
                width:calc(100vw - 273px);
            }
        } 
    }
    .close-sidebar{
      .open-filter{
        .child-table{
            &.project-child-table{
                width:calc(100vw - 126px);
            }
            .common-loader{
                width:calc(100vw - 126px);
            }
        } 
      }
    }
    .close-sidebar{
        .close-filter{
          .child-table{
              &.project-child-table{
                  width:calc(100vw - 381px);
              }
              .common-loader{
                  width:calc(100vw - 381px);
                  height:20px;
              }
          } 
        }
    }
}

@media(max-width:1199px){
    .child-table{
        &.project-child-table{
            width:calc(100vw - 40px);
        }
    }
}