.btn{
  font-size:17px;
  font-weight:600;
  border-radius:6px;
  padding:11px 30px;
  &.w75{
    width:75px;
  }
  &.btn-sm{
    font-size:13px;
    border-radius:2px;
    padding:9px 15px;
    &.icon-btn{
     padding:10px 15px 8px;
     white-space:nowrap;
      svg{
        margin: -2px 3px 0 0;
        color:$grey-6a6;
      }
    }
  }
  &.btn-md{
    font-size:13px;
    border-radius:2px;
    padding:11px 15px;
  }
  @media(max-width:767px){
      padding:9px 30px;
      font-size:16px;
  }
}

.btn-warning{
  background-color:$yellow-color;
  border-color:$yellow-color;
  &:focus,&:hover,
  &:first-child:hover,
  &:active:focus{
    box-shadow: none;
    background: $yellow-color;
    border-color:$yellow-color;
  }
}
.btn-link{
color:$dark-blue;
}

.btn-primary{
background-color:$blue-color;
border-color:$blue-color;
&:focus,&:hover
,&:first-child:hover,
&:active:focus{
  box-shadow: none;
  background: $blue-color;
  border-color:$blue-color;
}
}


.btn-info{
background-color:#F2FAFF;
border-color:#F2FAFF;
color:$blue-color;
&:focus,&:hover,
&:first-child:hover,
&:active:focus{
  box-shadow: none;
  background: #F2FAFF;
  border-color:#F2FAFF;
  color:$blue-color;
}
&.month-btn{
  &:focus,&:hover,
&:first-child:hover,
&:active:focus{
  background: #c2e4f9;
  border-color:#c2e4f9;
}
 
}
}

.btn-light{
  background-color:$grey-f5f;
  border-color:$grey-e2e;
  &:focus,&:hover,
  &:first-child:hover,
  &:active:focus{
    box-shadow: none;
    background-color:$grey-f5f !important;
    border-color:$grey-e2e !important;
  }
}
.btn-outline-light{
background-color:#fff;
border-color:$grey-d8e;
color:#000;
&:focus,&:hover,
&:first-child:hover,
&:active:focus{
  box-shadow: none;
  background-color:#fff !important;
  border-color:$grey-d8e !important;
  color:#000 !important;
}
}
.disabled-icon {
  cursor: not-allowed !important; /* Change cursor to indicate that the component is not clickable */
  opacity: 0.3; /* Reduce opacity to visually indicate that the component is disabled */
  /* Add any additional styling to visually indicate the disabled state */
}