/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #b1aeb2 #ffffff;
  }
  
  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}
  
::-webkit-scrollbar-track {
    background: #ffffff !important;
}
  
::-webkit-scrollbar-thumb {
    background-color: #b1aeb2 !important;
    border-radius: 10px !important;
}
  
body{
    font-family: 'Source Sans 3', sans-serif;
    font-weight:400;
}
.main-content{
    padding-left:242px;
    transition:all 0.2s;
    padding-right:20px;
    padding-bottom:30px;
}
.common-loader{
    height:100vh;
}
.inner-page{
    padding-top:65px;
    min-height:100vh;
    .common-loader{
        height:calc(100vh - 65px);
    }
    &.close-sidebar{
        .sidebar{
            width:73px;
            @media(min-width:1200px){
                .nav-item{
                    .nav-link{
                        span{display:none;}
                    }
                }
            }
        }
        @media(min-width:1200px){
            .filter-page{
                &.open-filter{
                    .filter{
                        left:-600px;
                    }
                }
            }
        }
        .main-content{
            padding-left:95px;
            @media(min-width:1200px){
                .filter{
                    left:73px;
                }
            }
        }
    }
}
.fc-day-disabled{
    background-color:#fff !important;
}
@media(max-width:1199px){
    .inner-page{
        padding-top:60px;
        &.close-sidebar{
            .sidebar{width:222px;left:0;}
            .main-content{
                padding-left:15px;
            }
        }
    }
    .main-content{
        padding:0 15px;
    }
}
.top-head{
    padding:22px 0 22px; 
    @media(max-width:991px){
        padding-top:20px;
    }
    @media(max-width:575px){
        flex-wrap:wrap;
        .input-icon{
            order:12;
            width:100%;
            margin-top:15px;
        }
    }
}
.common-heading{
    font-size: 21px;
    font-weight:700; 
    text-transform:capitalize;
}
@media(max-width:767px){
    .common-heading{
        font-size:18px;
    }  
}
.fw-medium{
    font-weight:500 !important;
}
// text color
.blue-color{
    color:$blue-color !important;
}
.yellow-color,.text-warning{
    color:$yellow-color !important;
}
.text-warning-dark{
    color:$dark-yellow !important;
}
.text-primary-dark{
    color:$dark-blue !important;
}
.text-black-3c3{
    color:$black-3c3 !important;
}
.cursor-pointer{
    cursor:pointer;
}
.grey-bfb{
    color:#BFBFBF !important;
    }
.grey-9c9{
color:#9C9D9F !important;
}
.grey-6a6{
    color:#6A6A6A !important;
    }
.offcanvas-backdrop{
    opacity:.1 !important;
}

.f-20{
    font-size:20px !important;
}
.f-18{
    font-size:18px !important;
}
.f-16{
    font-size:16px !important;
}
.f-15{
    font-size:14px !important;
}
.f-14{
    font-size:14px !important;
}
.f-13{
    font-size:13px !important;
}
.f-12{
    font-size:12px !important;
}
.mw-110{
    min-width:110px;
}
.ps-27{
    padding-left:27px !important;
}
.ps-47{
    padding-left:47px !important;
}
.ps-67{
    padding-left:67px !important;
}

.mw-118{
    min-width:118px;
}
.mw-92{
    min-width:92px;
}
.table-head-checkbox{margin-top:2px;}
.alert{
    padding:6px 1rem;
    border-radius: 2px;
    border:0;
    &.alert-danger{
        border-left:3px solid #842029;
        background-color:#f8d7da;
    }
    &.alert-success{
        border-left:3px solid #0f5132;
    }
}
.invoice-hrs-box{
    background-color:rgb(230,238,244);
    border-radius:3px;
}

// react datepicker
.react-datepicker{
    border-radius:0;
    border-color:$grey-d8e;
    font-family: 'Source Sans 3', sans-serif;
    &__header{
        background-color:$white;
        border-color:$grey-d8e;
        padding:12px 0 8px;
    }
    &__navigation{
        top:10px;
    }
    &__current-month{
        font-size:19px;
        color:$grey-6a6;
    }
    &__days-name{
        margin-bottom:-4px;
    }
    &__day-name{
        font-weight:600;
    }
    &__day,&__time-name,&__day-name{
        font-size: 16px;
        line-height: 31px;
        width: 30px;
        height: 30px;
        border-radius:2px !important;
        color:$grey-6a6;
    }
    &__day-name{
        font-size:15px;
    }
    &__day{
        &--in-range,&--in-selecting-range{
            background-color: #fcf6e1 !important;
            color:#dba200 !important;
            &:hover{
                background-color: #fcf6e1 !important;
                color:#dba200 !important;
            }
        }
        &--keyboard-selected, &--selected{
            background-color: #fcf6e1 !important;
            color:#dba200 !important;
            &:hover,&:focus{
                background-color: #fcf6e1 !important;
                color:#dba200 !important;
            }
        }
        &--outside-month, &--disabled{
            color:rgba($grey-6a6, 0.5);
        }
        &__month-text{
            &--keyboard-selected{
                background-color: #fcf6e1 !important;
                color:#dba200 !important;
            }
        }
    }
}
@media(max-width:1599px){
    .react-datepicker{
        &__header{
            padding:9px 0 8px;
        }
        &__current-month{
            font-size:16px;
        }
        &__day-names{
            margin-top: 6px;
        }
        &__day,&__time-name,&__day-name{
            margin:0.122em;
            font-size: 15px;
            line-height: 25px;
            width: 25px;
            height: 25px;
        }
    }
}
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month--in-range{
    background-color: #fcf6e1 !important;
    color:#dba200 !important;
}
.select-error {
    border: 1px solid red;
    border-radius: 5px;
}

.ticket-raised-drawer{
    .status-row{
        .badge{
            font-size: 14px;
            padding:6px 12px;
            border-radius: 50px;
            font-weight:400;
        }
    }
}
.toolName{
    position:fixed;
    right: 20px;
    top: 20px;
}



//    .date range css
.custom-date-range{
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, 
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, 
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, 
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
        color:$dark-yellow;
        font-weight: 600; 
    }
    .rdrMonthsVertical {
        flex-direction: row;
        @media(max-width:991px){
            flex-direction: column;
        }
    }
    .rdrInputRanges{
        display: none !important;
    }
    .rdrDateDisplayItemActive{
        border-color:rgba($yellow-color,0.4);
        background-color:rgba($yellow-color,0.4);
        input{color:$black-3c3;    font-weight: 600;}
    }
    .rdrStaticRange{
        span{
            font-weight: 600;
        }
        &.rdrStaticRangeSelected,&:focus{
            span{
                color:$black-3c3; 
            background-color:$yellow-color;
            }
        }
        &:hover{
           span{
            color: #dba200;
            background-color: #fcf6e1;
           }
        }
        &.rdrStaticRangeSelected{
            &:hover{
                span{
                    color:$black-3c3; 
                    background-color:$yellow-color;
                }
            }
        }
    }
    .rdrDayToday{
        .rdrDayNumber{
            span{
                &:after{
                background-color:$dark-yellow;
                }
            }
        }
    }
@media(max-width:600px){
    .rdrDefinedRangesWrapper{
        width:177px;
    }
}
@media(max-width:550px){
    flex-wrap:wrap;
    .rdrDefinedRangesWrapper{
        width:100%;border-right:0;
        .rdrStaticRanges{
            flex-wrap:wrap;
            flex-direction: row;
        }
        .rdrStaticRange{
            border:0;display: inline-block;
            span{
                border:1px solid $grey-d8e;
                padding: 5px 15px;
                margin:0 5px 10px 0;
                border-radius: 4px;
            }
        }
        .rdrInputRanges{
            padding-top:0;
            display:flex;
            .rdrInputRange{
                padding:5px 0;
                &:first-child{
                    margin-right:9px;
                }
            }
        }
    }
    .rdrCalendarWrapper{
        &.rdrDateRangeWrapper{
            width:auto;
            margin:0 auto;
        }
    }
}
@media(max-width:400px){
    .rdrMonth {
        width: 100%;
    }
}
}
.fc-daygrid-body.fc-daygrid-body-unbalanced {
    width: 100% !important;
}   

.fc-scrollgrid-sync-table{
    width: 100% !important;
    
}   

.fc-col-header{
    width: 100% !important;
}

element.style {
}
.custom-date-range .rdrDateDisplayItemActive input {
    color: #3C3C3C;
    font-weight: 600;
}
.rdrDateDisplayItemActive input {
    color: #7d888d;
}
.rdrDateDisplayItem input {
    background: none !important;
}
.custom-date-range .rdrDateDisplayItemActive {
    border: none !important;
    background: none !important;
}
.rdrDateDisplayWrapper {
    background: none !important;
}
.download-invoice-btn{
    position: absolute;
    top: -57px;
    right: -9px;
    padding: 6px 15px !important;
    font-size: 14px !important;
    svg{margin-top: -1px;}
}
.fc-icon{
    background-repeat:no-repeat;
    width: 9px !important;
    height: 19px !important;
    background-size: contain; 
    &.fc-icon-chevron-right{
        background-image:url('../assets/image/chevron-right-solid.svg');
        &:before{
           display:none;
        }
    }
    &.fc-icon-chevron-left{
        background-image:url('../assets/image/chevron-left-solid.svg');
        &:before{
           display:none;
        }
    }
}

.horizontal-scroll{
    overflow-x: auto;
    .row{
        flex-wrap: nowrap;
    }
}
.tableLink{
    &:hover{color:$dark-blue}
  }
.column-box{
    background-color:$white;
    box-shadow:0 0 20px rgba(#000, 0.06);
    transition:all 0.3s;
     &:after{
      content: "";
      position: absolute;
      top:0;
      left: 0;
      transition:all 0.3s;
      transform:scale(0);
      z-index: 1;
      width:0;
      height:0;
     }
    // &:hover{
    //   &:after{
    //     transform:scale(1);
    //     background-color:$dark-blue;
    //     width:100%;
    //     height:100%;
    //   }
    //   h1{color:$white}
    // }
  }
.walkthrough-pages{
    padding:60px 80px 60px 80px;
    max-width:1000px;
    @media(max-width:1399px){
        max-width:100%;
    }
    h1{
        margin-bottom: 20px;
    }
    p{
        margin-bottom: 10px;
        font-size: 18px
    }
    img{
        margin-top: 30px;
        margin-bottom: 30px;
        box-shadow: 5px 4px 10px -9px grey;
    }
}
.help-and-support{
    .main-content{
        padding-left: 320px;
    }
   
}
.page-number{
    bottom: 30px;
    right: 40px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;

}
.scroll-to-top-btn{
    display: block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-Index: 99;
    border: none;
    outline: none;
    background-color: white;
    cursor: pointer;
    padding: 10px 15px !important;
   
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 35%);
    z-index: 999;
    cursor: not-allowed;
  }
  .no-data-container {  
    max-width: 98.5%;
    text-align: center;
    background-color: #f8d7da;
    padding: 6px 1rem;
    border-left: 3px solid #842029;
    color: #842029;
    // margin-right: 10px;
    }