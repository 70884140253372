
.auth-page{
    .left-wrap{
         background-color:$grey-f5f;
         min-height:100vh;
         width:100%;
         display:flex;
         align-items:center;
         justify-content:center;
         .shape-img{
            position: absolute;
            top: 90px;
            left: -98px;
            // width: 236px;
         }
    }
    .bottom-text{
        position:absolute;
        bottom:110px;left:50%;
        transform:translateX(-50%);
        color:$black-3c3;font-size: 14px;
       }
    .right-wrap{
        min-height:100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px 50px;
        hr{background-color:$grey-d1d;opacity:1;}
       .form-card{
         width:496px;
         .form-control{
            border-color:$grey-d1d;
            &:focus{
                border-color:$yellow-color;
            }
         }
         h1{
            font-size:57px;
            font-weight:600;
            margin-bottom:30px;
        }
         .btn-warning{
            margin-top:30px;
        }
         .ortext{
            background-color:$grey-d1d;
            height:1px;
            margin:30px 0;
            span{
                position: absolute;
                top:50%;left:50%;
                transform:translate(-50%,-50%);
                background-color:#fff;
                border-radius:50%;
                padding:0 10px;
                display:block;
            }
         }
       }
      
    }
    @media(max-width:1799px){
        .bottom-text{
            bottom:50px;
        }
        .left-wrap{
            .shape-img{
               width:250px;
             }
             .bgimg{width:450px;margin-left:40px;}
        }
    }
    @media(max-width:1599px){
        .bottom-text{
            bottom:10px;
        }
        .left-wrap{
            .shape-img{top:60px;}
            .bgimg{ margin-left:100px;width:400px}
        }
        .right-wrap{
            .form-card{
                width:400px;
                h1{font-size:45px;}
            }
        }
    }
    @media(max-width:1199px){
        .left-wrap{
            .bgimg{ margin-left:55px;width:350px}
            .shape-img{width:228px;left:-89px;}
       }
        .right-wrap{
            .form-card{
                h1{font-size:35px;}
            }
        }
    }
    @media(max-width:991px){
        .left-wrap{
             .bgimg{ margin-left:0;width:285px}
        }
        .right-wrap{
            .form-card{
                h1{font-size:28px;}
            }
        }
    }
    @media(max-width:767px){
        .right-wrap{
            margin:0  auto;
            .form-card{
                width:300px;
                h1{font-size:25px;margin-bottom:20px;}
            }
        }
    }
}