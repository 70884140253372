.project-page{
    .child-table{
        .ReactVirtualized{
            &__Table{
                &__headerRow{
                    margin-top:0;
                }
            }
        }
    }
}