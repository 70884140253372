.employee-details{
    box-shadow:0 0 25px rgba(#000, 0.1);
    // overflow-y:auto;max-height:100vh;
    @media(min-width:992px){
        width:758px !important;
    }
    @media(min-width:1200px){
     &.full-width{
        width:90% !important;
        .toggle-arrow{
             left:-12px;
             top:20px;
        }
     }
    }
    .row{
        margin:0 -7px;
        [class^="col-"]{
            padding:0 7px;
            position: relative;
        }
    }
    .offcanvas-header{
        padding:16px 30px;
        .offcanvas-title{
            font-size:18px;
        }
        .btn-close{
            border-radius: 50%;
            margin:0;
            padding: 0;
            box-shadow: none;
            background-image:url('#{$imageurl}/close-button.png');
            height:25px;width:25px;
            background-position: center;
            background-size: contain;
            &:hover{
                opacity:0.5;
            }
        }
    }
    .offcanvas-body{
        padding:30px 25px 85px 30px;
        border-top-color:$grey-d8e !important;
        .card{
            margin-bottom:25px;
        }
        .hours-form-row{
            ul{
                overflow-x:auto;margin-left:30px;
                li{
                    min-width:100px;max-width:100px;
                    white-space:nowrap;
                    .form-label{display: none}
                    &:not(:last-child){
                        margin-right:10px;
                    }
                    .react-datepicker{
                        &__input-container{
                            .form-control{
                                height:30px;
                                background:transparent;
                                border:0;padding: 0;
                            }
                        }
                    }
                }
                .form-control-md{
                    height:36px;
                }
                .label-field{
                    height:auto;
                    font-weight:600;
                    color:rgba(#000, 0.5);
                }
                .flex-grow-1{
                    &:first-child{
                        margin-bottom:8px !important;
                    }
                }
            }
        }
        .btn-row{
            padding:20px 30px;
            position:absolute !important;
            bottom:0;left:0;width:100%;
            border-top:1px solid $grey-d8e;
            background-color:#fff;

            .btn{min-width:67px;}
        }
        .append-ele{
           padding-right:97px;
           .btn{
            min-width:40px;
            max-width:40px;
            height: 43px;
           }
        }
        .asignment-date{
            padding-right:191px;
            position:relative;
            .btn{
                position: absolute;
                right: 0;
                top: 23px;
            }
        }
    }
    .card-body{
        background-color:#F8F8F8 ;
        border-radius:5px;
        padding:20px 16px;
        .form-label{
            color:#888C8E;
            font-size: 12px;
            margin-bottom:5px;
        }
        .plusIcon{
              padding-right:50px;
            .btn{
                min-width:40px;
                max-width:40px;
                height: 43px;
                position: absolute;
                top:28px;right:0;
            }
        }
        .form-control,.form-select{
            height:45px;
        }
    }
    &.invoice-drawer{
        .offcanvas-header{
            padding-left:20px;
        }
        .offcanvas-body{
            padding:0 15px 30px;
            .approve-table{
                min-height:494px;
            }
        }
    }
    &.ticket-drawer{
        .offcanvas-body{
            padding-bottom:30px;
            .comment-list{
                max-height:250px;
            }
        }
    }
    &.noButton{
        .offcanvas-body{
            padding-bottom:30px;
        }
    }
    @media(max-width:991px){
        width:500px;
        .offcanvas-header{
            padding:12px 20px;
            .offcanvas-title{
                font-size:16px;
            }
        }
        .card-body{
            padding:12px 15px;
            .form-control,.form-select{
                height:40px;
            }
        }
        .offcanvas-body{
            padding:20px 20px 70px;
            .btn-row{
                &::before{
                    width:calc(100% + 40px);
                    left:-20px;
                }
            }
        }
    }
}