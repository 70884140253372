$white:#fff;
$black:#000;
$yellow-color:#FEC72E;
$dark-yellow:#DBA200;
$dark-blue:#1589CA;
$blue-color:#0074C9;
$light-blue:#E3F5FF;
$light-grey:#F5F8FA;
$grey-bg:#F5F5F5;
$grey-6a6:#6A6A6A;
$grey-e2e:#E2E2E2;
$grey-f5f:#F5F8FA;
$grey-d8e:#D8E2E8;
$grey-d1d:#D1D1D1;
$grey-fcf:#FCFCFC;
$grey-e0e:#E0E0E0;
$grey-f0f:#F0F5F8;
$black-3c3:#3C3C3C;


// image variable

$imageurl:'../../assets/image';
