.dashboard-page{
    padding-right:425px;
    .filter-desktop-btn{
        display: none !important;
    }
    .nav-tabs{
        position: relative;
        z-index: 1;
        margin-left:5px;
    }
    .calender-card{
        .card-header{
            padding:20px;
        }
    }
    .dashboard-sidebar{
        top:65px;
        right:0; 
        width:425px;
        height:calc(100vh - 65px);
        overflow-y:auto;
        overflow-x:hidden;
        transition:all 0.3s;
        .react-select{
            &__control{
                min-height:40px !important;
                border-radius: 2px !important;
                min-width: 150px;
                @media(min-width:1600px){
                    min-width: 180px; 
                }
            }
        }
        .card{
            border-radius: 3px;
            padding: 20px;
            border-color:$grey-d8e;
            .card-header{
                border-color:$grey-d8e;
            }
        }
        .accordion-button{
            display: block;
            &:after{display:none;}
            .trangle-icon{
                background-image: none;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 7px solid $blue-color;
                display:block;
                margin-top:5px;
            }
            &.collapsed{
                .trangle-icon{
                    border-top: 7px solid $blue-color;
                    border-bottom: 0;
                }
            }
        }
        .accordion-item{
            padding-left:30px;
            position: relative;
            border-bottom:0 !important;
            &.accordion-collapse {
                &:before{
                    content:"";
                    width:1px;
                    height:83%;
                    background-color:$grey-d8e;
                    position: absolute;
                    top: 6px;
                    left: 11px;
                }
            }
            &:not(:last-child){
                margin-bottom:20px;
            }
            .folder-icon{
                position:absolute;
                top:-2px;left:-29px;
                height: 20px;
                width: 20px;
                color:$grey-d8e;
                background: #fff;
            }
            &:last-of-type{
                .accordion-collapse{
                    border-left:0;
                }
            }
        }
        .accordion-body{
            padding:0;
        }
        .status-btn{
            a{
              border-radius:3px;
              padding:3px 8px;
              font-size: 10px;
              color:#5D5D5D;
              background-color:#EDEDED;
              text-decoration: none;
              margin-right:5px;
              &.start-btn{
                &.active,&:focus{
                    background-color:#2B9B01;
                    color:#fff;
                }
              }
              &.stop-btn{
                &.active,&:focus{
                    background-color:#FFD0D0;
                    color:red;
                  }
            }
            &.pause-btn{
                &.active,&:focus{
                    background-color:#FFE6A3;
                  }
                }
            }
        }
    }
    @media(max-width:1599px){
        padding-right:325px;
        .dashboard-sidebar{
            width:325px;
            .card{
                padding: 15px;
            }
        }
    }
    @media(max-width:1199px){
        padding-right:0;
        .dashboard-sidebar{
            top:60px;
            right:-400px;
            background-color:$white;
            height:calc(100vh - 60px);
            z-index: 99;
        }
        .close-sidebar{
            position: fixed;
            top:60px;
            right:-400px;
            transition:all 0.3s;
            z-index:98;
        }
        &.sidebar-open{
            .close-sidebar{
                right:322px;
            }
            .dashboard-sidebar{
                right:0;
            }
        }
    }
    @media(max-width:575px){
        .dashboard-sidebar{
            .react-select{
                &__control{
                    min-width:247px;
                    margin-top:10px;
                }
                &__menu{
                    min-width:247px;
                }
            }
        }
    }
    @media(max-width:479px){
        .dashboard-sidebar{
            top:100px;
            width:280px;
            height:calc(100vh - 100px);
            .card{
                padding: 10px;
            }
        }
        .close-sidebar{
            top:100px;
        }
        &.sidebar-open{
            .close-sidebar{
                right:278px;
            }
        }
    }
}