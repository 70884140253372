.header{
    padding: 1px 0;
    &.navbar{
        @include box-shadow(0, 0, 5px, rgba(#000, 0.15));
        height:65px;z-index: 999;
        svg{cursor: pointer;}
    }
    .role{
        margin-right:35px;
        .form-select{
            border:0;
        }
        .mb-3{
            margin-bottom:0 !important;
        }
    }
    .profile{
        padding:18px 8px 17px 26px;
        border-left:1px solid $grey-d1d;
        
        .dropdown-menu{
            width:200px;border-color:$grey-d8e;
            margin-top:14px;
            padding:15px 0 0;
            border-radius:3px;
            &:before{
                content:"";
                position:absolute;
                top:-8px;
                right:10px;
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $grey-d8e;
                border-radius:10px;
                
            }
            .dropdown-item{
                border-top:1.5px solid rgba($grey-d8e, 232);
                padding:12px 0;color:$yellow-color;
                &:focus,&:hover{
                    background-color: transparent;
                    // color:$yellow-color;
                }
               
            }
        }
    }
    @media(max-width:1199px){
        &.navbar{
            height:60px;
        }
        .role{margin-right:10px;}
        .profile{
            padding:13px 2px 15px 18px;
        }
    }
    @media(max-width:479px){
        &.navbar{
            .left-side{
                padding:7px 0;
            }
            // .right-side{
            //     width:100%;
            //     border-top:1px solid rgba($grey-d1d, 0.3);
            // }
        }
        .profile{
            padding:7px 2px 7px 15px;
            margin-left:auto;
            margin-top: 5px;
        }
    }
}

.tooldownload-button {
    color: black;
  
  }
  
  .tooldownload-button:hover {
    color:#1589CA !important; 
    border: none !important;/* Change the border color to blue on hover */
  }